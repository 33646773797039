<template>
  <div id="eneTrendChartDialog" ref="eneTrendChartDialog">
      <!-- :wrap-class-name="wrapClassName" -->
      <!-- :getContainer="() => this.$refs.eneTrendChartDialog" -->
    <a-modal v-model="visible"
      :title="title"
      :width="1440" 
      @ok="handleOk"
    >

        <template>
          <a-tabs type="card" @change="callback">
            <a-tab-pane key="1" tab="图表">
             <div id="eneTrendChartImg" ref="eneTrendChartImg" style="width:100%;height:400px;"></div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="Tab 2">
              Content of Tab Pane 2
            </a-tab-pane>
            <a-tab-pane key="3" tab="Tab 3">
              Content of Tab Pane 3
            </a-tab-pane>
          </a-tabs>
        </template>
          
      <!-- <div style="width:100%;height:100%;" v-loading="loading" :element-loading-text="$t('alarm.a0')">
      </div> -->


       <a-table :style="{width: '100%', height: '300px'}"
                    :columns="columns"
                    :data-source="tableData"
                    :pagination="false"
                    :scroll="xscroll"
                    bordered
                    size="small">
            
                   
                </a-table>
    </a-modal>
  </div>
</template>
<script>
import { getEnergySitesTrendData } from "../../../api/energy";
export default {
    name: 'EneTrendChartDialog',
    props: {
        title: {
            type: String,
            default: '',
        },
        wrapClassName:{
            type: String,
            default: 'full-modal',
        },
        trendParam:{
          type:Object,
          default:{
              clientId: 0,
              sitegrId: 0,
              siteId: 0,
              tenantId: 0,
          }
        },
        // pagination:{
        //     type:Object,
        //     default:{}
        // },
        // columns:{
        //     type:Array,
        //     default:[]
        // },tableData:{
        //     type:Array,
        //     default:[]
        // },xscroll:{
        //     type:Object,
        //     default:xscroll{ x: 1000,y:300 },
        // },
        // rptGroupId:{
        //     type:Number,
        // },
        // rptGroupName:{
        //     type:String,
        // },
        // modeno:{
        //     type:Number,
        // },
        // lmKind:{
        //     type:Number,
        // },
        // fatherChangeMethod: {
        //     type: Function,
        //     default: null
        // },
    }, 
    data() {
        return {
            visible:false,
            loading:false,
            option:{
              title: {
                text: 'Step Line'
              },
              tooltip: {
                trigger: 'axis'
              },
              legend: {
                data: ['模式', '温度']
              },
              grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
              },
              toolbox: {
                feature: {
                  saveAsImage: {}
                }
              },
              xAxis: {
                type: 'category',
                data: []
              },
              yAxis: [
                {
                  name: 'Flow(m^3/s)',
                  type: 'value'
                },
                {
                  name: 'Rainfall(mm)',
                  nameLocation: 'end',
                  alignTicks: true,
                  type: 'value',
                  inverse: false
                }
              ],
              series: [
                {
                  name: '模式',
                  type: 'line',
                  step: 'middle',
                  data: [1, 2, 2, 3, 1, 4, 2],
                  yAxisIndex: 0,
                },
                {
                  name: '温度',
                  type: 'line',
                  data: [20, 28, 27, 32, 21, 35, 28],
                  yAxisIndex: 1,
                },
              ]
            },
            trendChart:undefined,
            tableData:[],
        columns:[],xscroll:{ x: 1000,y:300 },

        };  
    },
    watch:{
        "$parent.showDialog":{
            handler(val){
                if(val){              
                    this.visible = val;
                    if(this.trendChart){
                      this.trendChart.dispose();
                    }
                    // setTimeout(this.drawLine(),100);
                   
                }
            },
            deep:true,
            immediate:true,
        },
        "visible":{
            handler(val){
                if(!val){
                    this.$parent.showDialog =  val;
                } else {
                     this.drawLine();
                }
            },
            deep:true,
            immediate:true,
        },
         "$parent.trendParam":{
            handler(val){
                 console.log('trendParam',val);
                  getEnergySitesTrendData(val)
              .then((res) => {
                  console.log("getEnergySitesTrendData Result",res);
                  // this.loading = false;
                  // if(res && res.errorCode == '00'){
                  //     this.rptGroup = res.data;
                  // } else {
                  //     this.$message.error(this.$t("energy.setting.reportGr.loadFail"));
                  // }
                  this.columns = res.data.columnList;
                  this.tableData = res.data.bodyList;
                  
              })
              .catch((err) => {
                  this.loading = false;
                  console.log(err);
              });
            },
            deep:true,
            immediate:true,
        },
        "this.$refs.eneTrendChartImg":{
             handler(val){
                console.log('eneTrendChartImg',val);
                // if(!val){
                //     this.$parent.showDialog =  val;
                // } else {
                //     //  this.drawLine();

                     
                // }
            },
            deep:true,
            immediate:true,
        },
    },
    activated(){
        let resizeDiv = document.getElementById('eneTrendChartImg');
        console.log('activated resizeDiv',resizeDiv);
    },
    mounted(){
      
    },
    methods: {
      handleOk(){
          
      },
      handleCancel(){
         this.visible=false;
      },
      
      drawLine() {
          let resizeDiv = document.getElementById('eneTrendChartImg');
          console.log('resizeDiv',resizeDiv);
          if(resizeDiv){
            console.log('trendChart',this.trendChart);
           
            this.trendChart = this.$echarts.init(document.getElementById('eneTrendChartImg'));
            this.trendChart.setOption(this.option);
            this.trendChart.resize();
          }
          // let listener = () => {
          //   myChart.resize();
          // };
          // EleResize.on(resizeDiv, listener);
      },
    }
  }
</script>
<style scoped>


#eneTrendChartDialog{
  width: 100%;
  height: 100%;
}
div /deep/.ant-modal-body{
    padding: 5px 10px;
}
/* .full-modal {
  .ant-modal {
    max-width: 100%;
    top: 0;
    padding-bottom: 0;
    margin: 0;
  }
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    height: calc(100vh);
  }
  .ant-modal-body {
    flex: 1;
  }
} */
</style>