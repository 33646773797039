<template>
  <a-table
    :rowSelection="rowSelection"
    :columns="columns"
    :data-source="data"
    :row-key="record => record.key" 
    :pagination="pagination"
    :scroll="Yscroll"
    size="middle"
  />
</template>
<script>


export default {
  name: 'CEneBuildGroupDetailPointTable',
  props: {
    data: {
      type: Array,
      default: [],
    },
    fatherChangeMethod: {
        type: Function,
        default: null
    },
    Yscroll:{
       type: Object,
       default: { y: 200 },
    },
    checkType:{             //多选/单选，checkbox or radio
       type: String,
       default: "checkbox",
    },
  }, 
  data() {
    let showTotal = this.$t("energy.showTotal");
    return {
      columns:[
          {
            title: "NO",          
            dataIndex: "NO",
            customRender: (text, record, index) =>
              `${
                (this.pagination.defaultCurrent - 1) * this.pagination.pageSize +
                index +
                1
              }`,
            width: 60,
            fixed:'left',
          },
          {
              title: 'TagPoint',
              dataIndex: 'tagPoint',
                width: 90,
              fixed:'left',
          },
          {
            title:  this.$t("energy.setting.reportGr.pointName"),
            dataIndex: 'signalName',
            width: 250,
            fixed:'left',
          },
          {
            title:  this.$t("energy.setting.reportGr.kikiName"),
            dataIndex: 'kikiName',
            width: 200,
          },
          {
            title: this.$t("energy.enedata113"),
            dataIndex: 'unit',
            width: 80,
            
          },
          // {
          //   title:  this.$t("energy.setting.reportGr.dataKind"),
          //   dataIndex: 'dataKind',
          //    width: 90,
          // },
           {
            title: this.$t('consume.a15'),
            dataIndex: 'clientName',
            width: 200,
            
          },
           {
            title: this.$t('energy.enedata254'),
            dataIndex: 'siteGrName',
            width: 240,
            
          },
           {
            title: this.$t('consume.a14'),
            dataIndex: 'siteName',
            width: 240,
            
          },
           {
            title: 'Gr',
            dataIndex: 'gr',
            width: 300,
          },
      ],
      pagination:  {
          total: 0, //数据总数
          pageSize: 100, //每页中显示10条数据
          showTotal: (total) => {
              return showTotal.replace('${total}',total);
          }, //分页中显示总的数据
          showQuickJumper: true, //是否可以快速跳转至某页
          defaultCurrent: 1, //默认当前页面数
          hideOnSinglePage: true, //只有一页时是否隐藏分页：默认值false
          onShowSizeChange: (current, pageSize) => {
              this.pagination.defaultCurrent = 1;
              this.pagination.pageSize = pageSize;
          },
          // 改变每页数量时更新显示
          onChange: (current, size) => {
              this.pagination.defaultCurrent = current;
              this.pagination.pageSize = size;
          },
      },
      selectedRowKeys:[],
    };
  },
  watch:{
    "data":{
      handler(val){
        this.selectedRowKeys = [];
      },
      deep:true,
      immediate:true,
    },
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        type:this.checkType,
      };
    },
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      if(this.fatherChangeMethod){
          this.fatherChangeMethod(selectedRowKeys)
      }
      this.selectedRowKeys = selectedRowKeys;
    },
  },
};
</script>
