<!--能源设置--趋势组组---->
<template>
  <div id="timeTrendChart" v-loading="loading" :element-loading-text="$t('alarm.a0')"> 

    <div class="header-title">
      {{ $t("energy.enedata536") }}
    </div>
    <div style="display:flex;width: 100%; height: 100%;">
      <div class="side">
        <div class="side-menu">
            <a-menu
              style="width: 100%;"
              mode="inline"
              :inline-collapsed="collapsed"
              :open-keys.sync="openKeys"
              @click="handleClick"
              v-model="selectedKeys"
            >
              <template v-for="item in rptGroupList">
                <a-menu-item v-if="!item.children" :key="item.no">
                  <a-icon type="pie-chart" />
                  <span>{{ item.text }}</span>
                </a-menu-item>
                <sub-menu v-else :key="item.no" :menu-info="item" :isdelete="false" />
              </template>
              
            </a-menu>
        </div>
          
      </div>
      <div class="main" ref="main" v-if="rptGroupId==0">
            <a-empty>
              <span slot="description"> {{ $t("energy.setting.reportGr.empty") }} </span>
            </a-empty>
      </div>
      <div class="main" ref="main" v-else> 
          <div style="width:100%;">
              <div class="rptGroup"  style="">
                  <div class="rptGroupName" style="">
                    <span>{{ $t("energy.setting.reportGr.grlabel") }}：</span><span>{{rptGroup.reportGroupName}}</span>
                  </div>
              </div>
              <div class="header-box">
                  <div class="header-column">
                      <span style="padding:5px;">{{$t('timeSetupTrend.a4')}}：</span>
                      <a-select v-model="timeView" placeholder="$t('timeSetupTrend.a5')" style="width: 200px">
                        <a-select-option value="1">
                          {{$t('timeSetupTrend.a6')}}
                        </a-select-option>
                        <a-select-option value="2">
                          {{$t('timeSetupTrend.a7')}}
                        </a-select-option>
                      </a-select>
                  </div>
                  <div class="header-column">
                        <!-- <span>{{ $t("energy.enedata340") }}</span> -->
                        <span style="padding:5px;">{{$t('timeSetupTrend.a8')}}：</span>
                        <a-date-picker
                          v-model="date"
                          :allowClear="false"
                          format="YYYY/MM/DD"
                          :title="$t('energy.enedata340')"
                        />
                    </div>  

                    <div class="header-column" v-if="timeView==='2'">
                        <!-- <span>{{ $t("energy.enedata340") }}</span> -->
                        <span style="padding:5px;">{{$t('timeSetupTrend.a9')}}：</span>
                        <a-time-picker
                          v-model="date"
                          :allowClear="false"
                          format="HH:mm"
                          :title="$t('energy.enedata340')"
                        />
                    </div>  
                    <!-- 图表显示 -->
                    <a-button type="primary" @click="showChart" :disabled="this.selectedPointIds.length==0">{{$t('energy.search')}}</a-button>

              </div>

              <div class="rptGroupPoint">
                  <ene-rpt-table :data="rptGroup.rptGroupList" :Yscroll="Yscroll" :fatherChangeMethod="setSelectedPointIds"></ene-rpt-table>
                  <!-- <p>注意：所选择信号点地计量单位不可超过两种。</p> -->
              </div>
              <a-modal v-model="visible"
                  :title="dialogTitle"
                  :width="1260" 
                  @ok="showChart"
                  :ok-text="$t('energy.enedata128')"
                  :cancel-text="$t('video.viddata054')"
              >
                  <template>
                    <div v-loading="mloading" :element-loading-text="$t('alarm.a0')" style="width:100%;height:640px;">
                        <a-tabs type="card" v-model="tabKey">
                          <a-tab-pane key="1" :tab="$t('energy.enedata139')" style="position: relative;">
                              <div id="eneTimeTrendChartImg" ref="eneTimeTrendChartImg" style="width:1180px;height:540px"></div>
                              <div class="btn-display button">
                                    <!-- CSV导出 -->
                                    <a-button type="primary" @click="switchChart(0)" :title="dptext"> {{dptext}}</a-button>
                                    <a-divider type="vertical" />
                                    <span>
                                        {{displayDate}}
                                    </span>
                                    <a-divider type="vertical" />
                                    <a-button type="primary" @click="switchChart(1)" :disabled="next" :title="dpntext">{{dpntext}}</a-button>
                              </div>
                          </a-tab-pane>
                          <a-tab-pane key="2" :tab="$t('energy.enedata449')" style="position: relative;">
                              <a-table
                                  :columns="columns"
                                  :data-source="tableData"
                                  :pagination="pagination"
                                  :scroll="xscroll"
                                  bordered
                                  size="small">
                              </a-table>
                              <div class="btn-display button">
                                    <!-- CSV导出 -->
                                    <a-button type="primary" @click="csvOut" :disabled="this.selectedPointIds.length==0">{{$t('energy.enedata042')}}</a-button>
                              </div>
                          </a-tab-pane>
                          
                        </a-tabs>
                        
                        
                    </div>

                  </template>
              </a-modal>
              <!-- <div>

                  <div class="btn-display button">
                  </div>
              </div> -->
              <!-- <trend-chart-dialog :title="dialogTitle" :trendParam="trendParam" :columns="columns" :tableData="tableData" ></trend-chart-dialog> -->
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getEneReportGrList , getEneReportGrDetail,getEnergySitesTimeSetupTrendData ,downloadEnergyCSVDataWithURL} from "../../../api/energy";
import SubMenu from "../components/measureGroup/eneMeasureGroupSubMenu.vue";
import EneReportGroupPointTable from "../components/measurePoint/eneBuildGroupDetailPointTable.vue";
import trendChartDialog from "./eneTrendChartDialog.vue";
import moment from "moment";
import { downloadUrl } from "../../../utils/utils";

export default {
  components: {
    'sub-menu': SubMenu,
    'ene-rpt-table':EneReportGroupPointTable,
    'trend-chart-dialog':trendChartDialog,
  },
  data() {   
      return {
        loading:false,
        tabKey:'1',
        selectedKeys:[],
        rptGroupList:[],
        rptGroupId:0,
        rptGroup:{
            reportGroupName: "",
            reportGroupNo: 0,
            reportType: 1,
            rptGroupList: [],
        },
        Yscroll:{x:1200,y:400},
        xscroll:{x:1100,y:400},
        rootSubmenuKeys: ["0"],
        openKeys: ["0"],
        visible:false,
        dataChange:false,
        selChange:false,
        collapsed: false,
        modeno:20,
        lmKind:3,
        reportGroupType:5,
        timeView:'1',
        trendDate:moment(),
        date:moment(),
        time:moment().startOf('day'),
        showDialog:false,
        dialogTitle:this.$t('energy.setting.reportGr.timeTrendGr.chartName'),// ' 时间设置趋势图',
        dialogTitle2:this.$t('energy.setting.reportGr.timeTrendGr.chartName'),//'时间设置趋势图',
        selectedPointIds:[],
        trendParam:{
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,
        },
        tableData:[],
        columns:[],
        pagination:{
          total: 0, //数据总数
          pageSize: 100, //每页中显示10条数据
          showTotal: (total) => ` ${total} ${this.$t("alarm.a8")}`, //分页中显示总的数据
          showQuickJumper: true, //是否可以快速跳转至某页
          defaultCurrent: 1, //默认当前页面数
          hideOnSinglePage: true, //只有一页时是否隐藏分页：默认值false
          onShowSizeChange: (current, pageSize) => {
            this.pagination.defaultCurrent = 1;
            this.pagination.pageSize = pageSize;
          },
          // 改变每页数量时更新显示
          onChange: (current, size) => {
            this.pagination.defaultCurrent = current;
            this.pagination.pageSize = size;
          },
        },
        option:{
          title: {
            text: 'Step Line',
            textStyle: {
              fontSize: 18,
              color: "#7682ce",
              fontWeight: "bold"
            }
          },
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['模式', '温度']
          },
          grid: {
            left: '6%',
            right: '6%',
            bottom: '5%',
            containLabel: true
          },
          toolbox: {
            feature: {
              saveAsImage: {}
            }
          },
          xAxis: {
            type: 'category',
            data: []
          },
          yAxis: [
            {
              name: 'Flow(m^3/s)',
              type: 'value'
            },
            {
              name: 'Rainfall(mm)',
              nameLocation: 'end',
              alignTicks: true,
              type: 'value',
              inverse: false
            }
          ],
          series: [
            {
              name: '模式',
              type: 'line',
              step: 'middle',
              data: [1, 2, 2, 3, 1, 4, 2],
              yAxisIndex: 0,
            },
            {
              name: '温度',
              type: 'line',
              data: [20, 28, 27, 32, 21, 35, 28],
              yAxisIndex: 1,
            },
          ]
        },
        trendChart:undefined,
        mloading:false,
        next:false,
        displayDate:'-',
        dptext:'',
        dpntext:'',
      };
  },
  activated(){
    console.log("activated",this.$store.getters.clientId);
     this.selectedKeys=[];
    this.rptGroupList=[];
    this.rptGroupId=0;
    this.rptGroup={
        reportGroupName: "",
        reportGroupNo: 0,
        reportType: 1,
        rptGroupList: [],
    };
    if(this.$store.getters.clientId){
        console.log("activated load data");
        this.loadReportGrList(0);
    }
  },
  mounted() { 
    // 调用表格高度计算方法
    this.tableSize();
    // 根据窗口变化计算表格高度
    window.addEventListener("resize", this.tableSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.tableSize);
  },
  computed: {
    
  },
  watch: {
    openKeys(val) {
      //console.log('openKeys', val);
    },
    "visible":{
      handler(val){
        if(!val){
          console.log(this.date);
        }
      },
      deep:true,
      immediate:true,
    },
    "$store.getters.clientId":{
      handler(val){
        //console.log(val);
        if(val!==""){
          this.loadReportGrList(0);
        }
      },
      deep:true,
      immediate:true,
    }
  },
  methods: {
    switchChart(act){
      let cdate = this.date;
      let kind = "day";
      if(this.timeView=='1'){
          kind = "day";
      }else if(this.timeView=='2'){
          kind = "hour";
      }
      if(act==0){
        cdate = cdate.add(-1,kind);
      }else{
        cdate = cdate.add(1,kind);
      }
      this.date = new moment(cdate);
      this.getTrendData();
    },
    csvOut(){
        let param =  {
            action:0,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,
            timeKind: this.timeView,
            date: this.date.format('YYYY/MM/DD'),
            time: this.date.format('HH:mm'),
            points:this.selectedPointIds,
        };
        this.mloading = true;
        console.log('downloadEnergyCSVDataWithURL param',param);
        downloadEnergyCSVDataWithURL('bivale/energy/data/site/trend/timeSetup/download',param)
        .then((res) => {
            let name = (this.$store.getters.siteServiceTypeName?this.$store.getters.siteServiceTypeName+'-':'')+this.$t('energy.enedata449')+'-'+moment().format('YYYYMMDDHHmmssSSS')+'.zip';
            console.log("downloadEnergyCSVDataWithURL Result",res);
            downloadUrl(res,name);
        })
        .catch((err) => {
            this.mloading = false;
            this.$message.error(this.$t('energy.enedata376'));
            console.log('downloadEnergyCSVDataWithURL',err);
        })
        .finally(()=>{
            this.mloading = false;
        });
    },
     getTrendData(){
        let params =  {
            action:0,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,
            timeKind: this.timeView,
            date: this.date.format('YYYY/MM/DD'),
            time: this.date.format('HH:mm'),
            points:this.selectedPointIds,
        };

        let now = new moment();
        if(this.timeView=='1'){
            this.next=(params.date===now.format('YYYY/MM/DD'));
            this.displayDate =  params.date;
            this.dptext = this.$t('energy.enedata445');
            this.dpntext = this.$t('energy.enedata446');
        }else if(this.timeView=='2'){
            this.next=(this.date.format('YYYY/MM/DD HH')===now.format('YYYY/MM/DD HH'));
            this.displayDate =  this.date.format('YYYY/MM/DD HH:mm');
            this.dptext = this.$t('energy.enedata447');
            this.dpntext = this.$t('energy.enedata448');
        }
        this.tabKey = '1';
        this.visible = true;
        this.mloading = true;
        getEnergySitesTimeSetupTrendData(params)
              .then((res) => {
                  console.log("GetEnergySitesTrendData Result",res);

                  this.mloading = false;

                  if(res && res.errorCode == '00'){
                     
                      this.columns = res.data.columnList;
                      this.tableData = res.data.bodyList;
                      this.option.xAxis = res.data.chartData.xAxis;
                      this.option.yAxis = res.data.chartData.yAxis;
                      this.option.series = res.data.chartData.series;
                      this.option.legend = res.data.chartData.legend;
                      this.option.title.text  = res.data.chartData.title;
                      this.dialogTitle = this.dialogTitle2 +"——"+ res.data.chartData.title;
                      this.drawLine();
                  } else {
                      this.$message.error(this.$t("energy.setting.reportGr.loadFail"));
                  }
                  
              })
              .catch((err) => {
                  this.loading = false;
                  console.log(err);
              });
      },
      handleOk(){
          
      },
      drawLine() {
         
          let resizeDiv = document.getElementById('eneTimeTrendChartImg');
          console.log('resizeDiv',resizeDiv);
          if(resizeDiv){
           if(this.trendChart){
                this.trendChart.dispose();
            }
            this.trendChart = this.$echarts.init(document.getElementById('eneTimeTrendChartImg'));
            this.trendChart.setOption(this.option);
            this.trendChart.resize();
          }
      },
      // 表格高度计算
      tableSize() {
          setTimeout(() => {
              let height = this.$refs.main.clientHeight;
              let width = this.$refs.main.clientWidth;
              let tableHeight = height - 130;
              // this.Yscroll.y = tableHeight;
              this.Yscroll.x = width;
          }, 0);
      },
      handleClick(e) {
          this.rptGroupId = parseInt(e.key);
          if(parseInt(this.rptGroupId)>0){
            this.selChange = true;
          }
          this.selectedPointIds.length=0;
          this.loadReportGrDetail();
      },
      loadReportGrDetail() {
          if(parseInt(this.rptGroupId)>0){
              let params =  {
                  action:0,
                  clientId: this.$store.getters.clientId,
                  siteId: this.$route.query.id,
                  sitegrId: this.$store.getters.sitegrId,
                  tenantId: 0,
                  reportGroupType: this.reportGroupType,
                  reportGroupNo: this.rptGroupId,
              };
              console.log("loadMeasureDetail Params",params);
              this.loading = true;
              getEneReportGrDetail(params)
              .then((res) => {
                  console.log("loadMeasureDetail Result",res);
                  this.loading = false;
                  if(res && res.errorCode == '00'){
                      this.rptGroup = res.data;
                  } else {
                      this.$message.error(this.$t("energy.setting.reportGr.loadFail"));
                  }

              })
              .catch((err) => {
                  this.loading = false;
                  console.log(err);
              });
          }
      },
      loadReportGrList(action){
        let params =  {
            action:action,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,
            reportGroupType: this.reportGroupType,
        };
        console.log("loadReportGrList Params",params);
        this.loading = true;
        getEneReportGrList(params)
        .then((res) => {
            console.log("loadReportGrList Result",res);
            if(res && res.errorCode == '00'){
                this.rptGroupList = res.data.rptGroupList;
            } else {
                this.$message.error(this.$t("energy.setting.reportGr.loadFail"));
            }     
            this.loading = false;
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        });
      },
      showChart(){
        
          this.getTrendData();
      },
      setSelectedPointIds(selectedRowKeys){
          console.log('selectedPointIds',this.selectedPointIds);
          this.selectedPointIds =selectedRowKeys;
      },

  }
};
</script>

<style scoped>
#timeTrendChart {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  font-family:apple-system, BlinkMacSystemFont, 'Segoe UI','PingFangSC-Regular', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'
}
ul, li {
  list-style: none;
  margin: 0;
}

.header-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 18px;
}
.header-title::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 30px;
  background: #7585cd;
  margin-right: 20px;
}
.side {
  width: 280px;
  height: calc(100% - 24px);
  box-shadow: 0px 3px 8px #e7e6e6;
}
.side-menu{
  width:100%;
  max-height:  calc(100% - 50px);
  overflow: auto;
}
.main {
  width: calc(100% - 280px);
  height: calc(100% - 24px);
  padding: 5px;
  box-shadow: 0px 3px 8px #e7e6e6;
}
.mg-add{
  width: 24px;
  height: 24px;
  border-radius: 24px;
  font-size: 22px;
  color: #7682ce;
  border:1px solid #FFFFFF;
  margin-top: 5px;
}
.mg-add:hover{
  color: #FFFFFF;
  background-color: #7682ce;
  border:1px solid #7682ce;
 
}
.rptGroup{display: flex; justify-content: flex-start;}
.rptGroupName{margin:3px;padding:3px;font-size:14px;line-height:24px;}
.rptGroupPoint{width: 100%;height: calc(100% - 100px);}
.btn-display{
  top:-65px;right: 3px;position:absolute;
}
.button{
  padding: 10px 0;
}
.header-box{
  padding:5px;
  display: flex;
  align-items: flex-start;
  /* justify-content: space-between; */
}
.header-box button{
  text-align: right;
}

.header-column {
  display: flex;
  align-items: flex-start;
  margin-right: 5px;
}
.header-column span {
  font-size: 14px;
}
div /deep/.ant-modal-body{
    padding: 5px 10px;    
}
</style>
